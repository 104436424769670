






































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditEmergencyFundNewFundViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-emergency-fund-goal-plan/edit-emergency-fund-new-fund-view-model';

@Component({ name: 'EditEmergencyFundNewFund' })
export default class EditEmergencyFundNewFund extends Vue {
  edit_emergency_fund_new_fund_view_model = Vue.observable(
    new EditEmergencyFundNewFundViewModel(this),
  );

  async mounted() {
    await this.edit_emergency_fund_new_fund_view_model.initialize();
  }
}
