import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetFinalInvestmentDateCalculatorQuery
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/application/queries/get-final-investment-date-calculator-query';
import UpdatePlanEmergencyFundInvestorGoalCommand
  from '@/modules/flagship/emergency-fund-investor-goal/application/command/update-plan-emergency-fund-investor-goal-command';
import EditEmergencyFundGoalPlanBudgetService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-budget-service';
import EditEmergencyFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-plan-service';
import EditEmergencyFundGoalPlanCurrentAmountService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-amount-service';
import EditEmergencyFundGoalPlanDefineByService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-define-by-service';

// Domain
import { FinalInvestmentDateCalculatorDto }
  from '@/modules/flagship/investor-goal/final-investment-date-calculator/domain/dtos/final-investment-date-calculator-dto';
import { UpdatePlanEmergencyFundDto }
  from '@/modules/flagship/emergency-fund-investor-goal/domain/dtos/update-plan-emergency-fund-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import { DatetimeValue } from '@/modules/shared/domain/value-objects/datetime-value';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EditEmergencyFundNewFundViewModel {
  @Inject(TYPES.GET_FINAL_INVESTMENT_DATE_CALCULATOR_QUERY)
  private readonly get_final_investment_date_query!: GetFinalInvestmentDateCalculatorQuery;

  @Inject(TYPES.UPDATE_PLAN_EMERGENCY_FUND_INVESTOR_GOAL_COMMAND)
  private readonly update_plan_emergency_fund_command!: UpdatePlanEmergencyFundInvestorGoalCommand;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_BUDGET_SERVICE)
  private readonly budget_service!: EditEmergencyFundGoalPlanBudgetService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditEmergencyFundGoalPlanCurrentPlanService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_AMOUNT_SERVICE)
  private readonly current_amount_service!: EditEmergencyFundGoalPlanCurrentAmountService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_DEFINE_BY_SERVICE)
  private readonly define_by_service!: EditEmergencyFundGoalPlanDefineByService;

  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.DATETIME_VALUE)
  readonly datetime_value!: DatetimeValue;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-emergency-fund-goal-plan.new_emergency_fund';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  plan_information = [
    {
      label: this.translate('target_amount'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('current_amount'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('monthly_contribution'),
      current_plan: '',
      new_plan: '',
    },
    {
      label: this.translate('target_date'),
      current_plan: '',
      new_plan: '',
    },
  ];

  update_plan_dto: UpdatePlanEmergencyFundDto = {
    emergency_fund_investor_goal: '',
    defined_by: '',
    desired_amount: 0,
    fixed_time_adjusted: 0,
    investor_profile: {
      net_monthly_income: 0,
      net_monthly_expenses: 0,
    },
    investor_goal: {
      initial_amount: 0,
      accumulated_amount: 0,
      monthly_required_amount: 0,
      final_investment_date: '',
      initial_investment_date: '',
    },
  };

  final_investment_date = '';

  initial_investment_date = '';

  is_loading = false;

  initialize = async () => {
    await this.setInitialValues();
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getBorderClass(index: number) {
    return (this.isIndexLessThanThree(index)) ? '' : 'rounded-br-lg';
  }

  getBudgetInformation = () => (this.budget_service.getBudgetInformation());

  getCurrentPlanInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  getCurrentAmountInformation = () => (this.current_amount_service.getCurrentAmountInformation());

  getDefineByInformation = () => (this.define_by_service.getDefineByInformation());

  isIndexLessThanThree(index: number) {
    return index < 3;
  }

  setInitialValues = async () => {
    const budget_information = this.getBudgetInformation();
    const current_plan = this.getCurrentPlanInformation();
    const current_amount = this.getCurrentAmountInformation();
    const define_by = this.getDefineByInformation();
    const amount_to_reach_goal = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.contribution : define_by.by_time.you_must_save;
    const months = (define_by.is_defined_by_contribution) ? define_by
      .by_contribution.months_to_reach_goal : define_by.by_time.months_to_reach_goal;
    let new_emergency_amount = budget_information.new_emergency_fund;
    let new_plan_current_amount = this.parseCurrencyToNumber(current_plan.current_amount);
    if (current_amount.there_is_more_savings === 'yes' && current_amount.strategy_selected === 'other') {
      new_emergency_amount -= current_amount.additional_amount;
    }
    if (current_amount.there_is_more_savings === 'yes' && current_amount.strategy_selected === 'pocket') {
      new_plan_current_amount += current_amount.additional_amount;
    }
    await this.loadNewFinalInvestmentDate(months);
    this.plan_information[0].current_plan = current_plan.target_amount;
    this.plan_information[0].new_plan = this.getAmountFormatted(new_emergency_amount);
    this.plan_information[1].current_plan = current_plan.current_amount;
    this.plan_information[1].new_plan = this.getAmountFormatted(new_plan_current_amount);
    this.plan_information[2].current_plan = current_plan.monthly_required_amount;
    this.plan_information[2].new_plan = this.getAmountFormatted(amount_to_reach_goal);
    this.plan_information[3].current_plan = current_plan.final_investment_date;
    this.plan_information[3].new_plan = this.date_formatter.formatDate(this.final_investment_date, 'DD/MM/YYYY');
    this.update_plan_dto = {
      emergency_fund_investor_goal: current_plan.emergency_fund_id,
      defined_by: (define_by.is_defined_by_contribution) ? 'contribution' : 'time',
      desired_amount: new_emergency_amount,
      fixed_time_adjusted: months,
      investor_profile: {
        net_monthly_income: budget_information.monthly_income,
        net_monthly_expenses: budget_information.monthly_expenses,
      },
      investor_goal: {
        initial_amount: new_plan_current_amount,
        accumulated_amount: (define_by.is_defined_by_contribution) ? define_by.by_contribution
          .accumulated_amount : define_by.by_time.accumulated_amount,
        monthly_required_amount: amount_to_reach_goal,
        final_investment_date: this.final_investment_date,
        initial_investment_date: this.initial_investment_date,
      },
    };
  }

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  loadNewFinalInvestmentDate = async (months_to_add: number) => {
    try {
      this.initial_investment_date = this.datetime_value.create().toString();
      const payload: FinalInvestmentDateCalculatorDto = {
        initial_investment_date: this.initial_investment_date,
        period_in_months: months_to_add,
      };
      const { final_investment_date } = await this.get_final_investment_date_query.execute(payload);
      this.final_investment_date = final_investment_date;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_final_investment_date_query'));
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  updateNewEmergencyFund = async () => {
    try {
      this.is_loading = true;
      await this.update_plan_emergency_fund_command.execute(this.update_plan_dto);
      this.view.$emit('nextStep');
      this.is_loading = false;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_plan_emergency_fund_command'));
    }
  }
}
